<template>
  <main>
      <h3>If you need joint the meeting please click in Join Meeting</h3>
    <button class="button-signature" @click='getSignature'>Join Meeting</button>
  </main>
</template>

<script>
// import { db } from '@/config/firebase'
import { ZoomMtg } from '@zoomus/websdk'
import axios from 'axios'

export default {
  name: 'Zoom',
  created () {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.0.1/lib', '/av')
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareWebSDK()
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load('es-ES')
    ZoomMtg.i18n.reload('es-ES')
  },
  mounted () {
    ZoomMtg.inMeetingServiceListener('onUserJoin', (data) => {
      console.log('inMeetingServiceListener onUserJoin', data)
    })
  },
  data () {
    return {
      apiKey: 'jtUj95C4RYSviVqZOpxsWg',
      leaveUrl: process.env.VUE_APP_URL_ZOOM + '/zoom/' + this.$route.params.idCoProperty + '/' + this.$route.params.meetingId,
      meetingNumber: this.$route.params.meetingId,
      passWord: '',
      role: 1,
      signatureEndpoint: 'https://us-central1-app-calendario-1.cloudfunctions.net/app/v1/api/meetingsSignature',
      userEmail: '',
      userName: JSON.parse(localStorage.getItem('userInformation')).name,
      // pass in the registrant's token if your meeting or webinar requires registration. More info here:
      // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/meetings/join#join-registered
      // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/webinars/join#join-registered-webinar
      registrantToken: ''
    }
  },
  methods: {
    getSignature () {
      axios.post(this.signatureEndpoint, {
        meetingNumber: this.meetingNumber,
        role: this.role
      }).then(res => {
        this.startMeeting(res.data.signature)
      }).catch(() => {
        console.log('')
      })
    },
    startMeeting (signature) {
      document.getElementById('zmmtg-root').style.display = 'block'
      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        isSupportAV: true,
        success: (success) => {
          ZoomMtg.join({
            meetingNumber: this.meetingNumber,
            userName: this.userName,
            signature: signature,
            apiKey: this.apiKey,
            userEmail: this.userEmail,
            passWord: this.passWord,
            success: (success) => {
              console.log(success)
            },
            error: (error) => {
              console.log(error)
            }
          })
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="css" scoped>

@import '../../../../node_modules/@zoomus/websdk/dist/css/bootstrap2.css';
@import '../../../../node_modules/@zoomus/websdk/dist/css/react-select.css';
/* @import url('https://source.zoom.us/2.0.1/css/bootstrap.css'); */
/* @import url('https://source.zoom.us/2.0.1/css/react-select.css'); */
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

.button-signature {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

.button-signature:hover {
  background-color: #2681F2;
}

</style>
